import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As product owner / technical lead, this project has been my core focus at
`}<a parentName="p" {...{
        "href": "/qintel"
      }}>{`Qintel`}</a>{`. The goal of this platform is to provide a unified search and
analysis interface for threat analysts to perform cyber investigations. The
product is used by a number of different agencies/companies to collaborate
on and further their investigations.`}</p>
    <p>{`If you are familiar with cybersecurity, you probably know that there are a
wide variety of data types used for analysis. These include whois records, pDNS
records, account registrations, communications, exposed credentials, netflow
data and more. Cases usually involve finding a trail by combining these
different entities left by a criminal actor into a more cohesive picture.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3523e19d9080417676668b35f718930/644c5/crosslink-landing.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHKe0omMH//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQERL/2gAIAQEAAQUCtQXOGH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAQAGPwJr/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQETFBkf/aAAgBAQABPyFF9Hk2CxrcP//aAAwDAQACAAMAAAAQLz//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QTJ//xAAVEQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPxBZ/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARMSFBUf/aAAgBAQABPxBrmyodBHpA25BgaVsOqvJX6z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "crosslink landing",
            "title": "crosslink landing",
            "src": "/static/e3523e19d9080417676668b35f718930/644c5/crosslink-landing.jpg",
            "srcSet": ["/static/e3523e19d9080417676668b35f718930/158ba/crosslink-landing.jpg 360w", "/static/e3523e19d9080417676668b35f718930/80e3c/crosslink-landing.jpg 720w", "/static/e3523e19d9080417676668b35f718930/644c5/crosslink-landing.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`An investigation typically starts with a selector or indicator of compromise.
For example, you might see a malicious domain being used. In CrossLink, you
could search that domain to find the whois and pDNS records associated with it.
The whois records might give you an email or name if they weren't privacy-
protected. The pDNS records could lead you to more domains registered by the
same actor which you could pivot on for more whois information. You might also
see an actor discussing that domain in their communications. Once you've
obtained an email or domain that you're confident in, you start looking for the
actor's account registrations which will likely have more solid attribution
information.`}</p>
    <p>{`Along the way, you would build an investigation by saving every entity that
seems linked to your initial selector. Within the investigation, you have the
ability to revisit entities, see high-level summaries of everything it contains,
add additional external links and selectors, or export everything.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/10957659fa982769bc3362e696062a92/644c5/crosslink-investigation.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe3sopTD/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAERE//aAAgBAQABBQKIiIjRmjNWf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAAAABMUH/2gAIAQEABj8CqFQqGMY//8QAFhABAQEAAAAAAAAAAAAAAAAAAGHx/9oACAEBAAE/IcxmMhMmRP/aAAwDAQACAAMAAAAQIA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAADAQADAAAAAAAAAAAAAAAAAfHRIZHB/9oACAEBAAE/EE589Ual5iNJT0lPSE9P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "crosslink investigation",
            "title": "crosslink investigation",
            "src": "/static/10957659fa982769bc3362e696062a92/644c5/crosslink-investigation.jpg",
            "srcSet": ["/static/10957659fa982769bc3362e696062a92/158ba/crosslink-investigation.jpg 360w", "/static/10957659fa982769bc3362e696062a92/80e3c/crosslink-investigation.jpg 720w", "/static/10957659fa982769bc3362e696062a92/644c5/crosslink-investigation.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`I've learned an inordinate amount working on this project. We've been through
4 major iterations of the API and GUI since I've been on board, and cut
countless minor and patch releases. We've taken it from a simple ReSTful API to
a fairly advanced API and GUI used both internally and externally. My primary
responsibilities include ui/ux `}<em parentName="p">{`design`}</em>{`, ui `}<em parentName="p">{`engineering`}</em>{` and `}<em parentName="p">{`architecture`}</em>{`,
working with `}<em parentName="p">{`stakeholders`}</em>{` to determine priorities, and `}<em parentName="p">{`coordinating`}</em>{` with
the other core developers to deliver `}<em parentName="p">{`fixes and features`}</em>{`.`}</p>
    <p>{`Due to the sensitive nature of the application, I can't share too much in terms
of screenshots.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      